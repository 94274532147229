import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import * as styles from "./index.module.scss";

const Feature = ({ imageUrl, title, text, textToRight = true }) => {
    return (
        <div
            className={classnames({
                [styles.featureWrapperTextRight]: textToRight,
                [styles.featureWrapperTextLeft]: !textToRight,
            })}
        >
            <div
                className={classnames({
                    [styles.imageWrapperTextRight]: textToRight,
                    [styles.imageWrapperTextLeft]: !textToRight,
                })}
            >
                <img src={imageUrl} className={styles.img} alt="Feature" />
            </div>
            <div className={styles.textWrapper}>
                <span className={styles.title}>{title}</span>
                <span className={styles.text}>{text}</span>
            </div>
        </div>
    );
};

Feature.propTypes = {
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    text: PropTypes.string,
    textToRight: PropTypes.bool,
};

export default Feature;
