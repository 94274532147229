import * as React from "react";
import PropTypes from "prop-types";

import * as styles from "./index.module.scss";

import checkboxImage from "../../../../../../../../static/img-checkbox.svg";

const CheckboxList = ({ title }) => {
  return (
    <div className={styles.checkboxListWrapper}>
      <img
        className={styles.checkboxImage}
        src={checkboxImage}
        alt="Checkbox"
      />
      <span className={styles.checkboxTitle}>{title}</span>
    </div>
  );
};

CheckboxList.propTypes = {
  title: PropTypes.string,
};

export default CheckboxList;
