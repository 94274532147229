import * as React from "react";

import * as styles from "./index.module.scss";
import screenImage from "../../../../../../static/screen_img.png";
import screenImage768 from "../../../../../../static/screen_img768.png";
import screenImage320 from "../../../../../../static/screen_img320.png";

import CheckboxList from "./components/CheckboxList";

const Features = () => {
    return (
        <>
            <div className={styles.featuresWrapper}>
                <div className={styles.checkboxList + ' ' + styles.left}>
                    <CheckboxList title="Bookkeeping"/>
                    <CheckboxList title="Shipping Automation"/>
                    <CheckboxList title="Task Management"/>
                </div>

                <div className={styles.checkboxList + ' ' + styles.right}>
                    <CheckboxList title="Crosslisting"/>
                    <CheckboxList title="Inventory Management"/>
                    <CheckboxList title="Analytics"/>
                </div>
                <picture className={styles.screenImage}>
                    <source srcSet={screenImage320} media="(max-width: 768px)"/>
                    <source srcSet={screenImage768} media="(max-width: 1024px)"/>
                    <source srcSet={screenImage} media="(min-width: 1025px)"/>
                    <img className={styles.screenImage} src={screenImage} alt="Screen"/>
                </picture>
            </div>

            <div className={styles.featuresWrapperDesc}>
                <div className={styles.checkboxList + ' ' + styles.left}>
                    <CheckboxList title="Bookkeeping"/>
                    <CheckboxList title="Shipping Automation"/>
                    <CheckboxList title="Task Management"/>
                </div>
                <picture className={styles.screenImage}>
                    <source srcSet={screenImage320} media="(max-width: 768px)"/>
                    <source srcSet={screenImage} media="(max-width: 1024px)"/>
                    <source srcSet={screenImage} media="(min-width: 1025px)"/>
                    <img className={styles.screenImage} src={screenImage} alt="Screen"/>
                </picture>
                <div className={styles.checkboxList + ' ' + styles.right}>
                    <CheckboxList title="Crosslisting"/>
                    <CheckboxList title="Inventory Management"/>
                    <CheckboxList title="Analytics"/>
                </div>

            </div>
        </>
    );
};

export default Features;
