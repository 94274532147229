import React, { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

import PageLayout from "../components/PageLayout";
import Header from "../components/Header";
import TryItSection from "../sections/IndexPage/TryIt";
import FeaturesSection from "../sections/IndexPage/Features";
import GetStartedSection from "../sections/IndexPage/GetStarted";
import Footer from "../components/Footer";

const LandingSections = () => {
  const { boot } = useIntercom();

  useEffect(() => {
    boot();
  }, []);

  return (
    <>
      <TryItSection />
      <FeaturesSection />
      <GetStartedSection />
      <Footer />
    </>
  );
};

const IndexPage = () => {
  return (
    <>
      <Header />
      <PageLayout title="Fearn">
        <LandingSections />
      </PageLayout>
    </>
  );
};

export default IndexPage;
