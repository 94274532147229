import * as React from "react";

import * as styles from "./index.module.scss";

import SectionLayout from "../../../components/SectionLayout";
import BackgroundImage from "../../../components/BackgroundImage";
import Features from "./components/Features";
import TryItButton from "./components/TryItButton";
import BackgroundSvg from "../../../images/im_hero_1024.png";
import BackgroundSvg768 from "../../../images/im_hero_768.png";
import BackgroundSvg320 from "../../../images/im_hero.png";

const TryItSection = () => {
  return (
    <SectionLayout className={styles.tryItSection}>
      <BackgroundImage
          backgroundImageDesktop={BackgroundSvg}
          backgroundImageTablet={BackgroundSvg768}
          backgroundImageMobile={BackgroundSvg320}
          imageStyle={styles.backgroundImage}
      >
        <span className={styles.backgroundImageChildren}>
          For resellers with growing business needs
        </span>
      </BackgroundImage>
      <Features />
      <TryItButton />
    </SectionLayout>
  );
};

export default TryItSection;
